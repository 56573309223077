import { StylelessInput as Input, StylelessInputProps as InputProps } from 'adam-frontend-shared';
import styled from 'styled-components';
import Icon from 'components/Icon';
import LocalizedText, { TranslationKey } from 'components/LocalizedText';
import { TextVariant } from 'components/Text';

export interface Props extends InputProps {
  label: TranslationKey;
  variant: TextVariant;
}

const LabelContainer = styled.label`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;

const HiddenCheckbox = styled(Input)`
  display: none;
`;

const Checkmark = styled(Icon).attrs(() => ({ icon: 'check', size: '28px' }))`
  opacity: 0;
  transition: opacity 0.15s;
`;

const CheckboxDisplay = styled.div.attrs(() => ({ children: <Checkmark /> }))<{ variant: TextVariant }>`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  flex: 0 0 32px;
  background-color: ${({ theme: { color } }) => color.textInputBackground};
  display: grid;
  place-items: center;

  ${HiddenCheckbox}: checked + & {
    ${Checkmark} {
      opacity: 1;
    }
  }
`;

export default function Checkbox(props: Props): JSX.Element {
  const { className, label, variant, ...inputProps } = props;
  return (
    <LabelContainer className={className}>
      <HiddenCheckbox {...inputProps} type="checkbox" />
      <CheckboxDisplay variant={variant} />
      <LocalizedText translationKey={label} variant={variant} category="caption" />
      {/* <StyledInput hasError={!!errorMessage} {...inputProps} /> */}
    </LabelContainer>
  );
}
