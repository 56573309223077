import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function useToastError(error?: Error | null, toastId?: string): void {
  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast(error.message, { toastId });
    }
  }, [error, toastId]);
}
