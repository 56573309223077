import { TransactionRecord } from '@adam-vault/adam-sdk-coffeedao';
import { useAsyncMemo } from 'adam-frontend-shared';
import { subDays } from 'date-fns';
// import { BigNumber } from 'ethers';
// import { CustomError } from 'constants/error/CustomError';
// import { TransactionType } from 'constants/transaction';
// import { TransactionRecord } from 'types/TransactionRecord';
// import { toast } from 'utils/toastUtils';
import useAdam from './useAdam';
// import useCoffeeDao from './useCoffeeDao';
import useCoffeeDaoClient from './useCoffeeDaoClient';
import { useIsAuthenticated } from './useIsAuthenticated';
import useMeta from './useMeta';
// import useWeb3 from './useWeb3';

type Return = {
  records: TransactionRecord[];
  reloadRecords: () => void;
  isLoading: boolean;
};

export default function useCoffeeDaoTokenTransactions(): Return {
  const coffeeDaoClient = useCoffeeDaoClient();
  const { isAuthenticated } = useIsAuthenticated();
  // const { address: eoaAddress } = useWeb3();
  const adam = useAdam();
  // const coffeeDao = useCoffeeDao();
  const { isLoading: isLoadingMeta, meta, error: metaError } = useMeta();

  const [records, { isLoading, refetch }] = useAsyncMemo<TransactionRecord[]>(
    async () => {
      if (!isAuthenticated || isLoadingMeta || !meta || metaError || !coffeeDaoClient) {
        return [];
      }

      try {
        const selfClaim = adam.loadSelfClaim(meta.SELFCLAIM_BUDGET_ADDRESS);

        return await coffeeDaoClient.request(
          selfClaim.transactionRecords(meta.UTIL_TOKEN_ADDRESS, subDays(new Date(), 30), new Date())
        );
      } catch {
        // TODO: error handling

        return [];
      }
    },
    [isAuthenticated, meta],
    []
  );

  return {
    records,
    reloadRecords: refetch,
    isLoading,
  };
}
