import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ChangeNicknameModal from 'components/ChangeNicknameModal';
import Icon from 'components/Icon';
import { JWT_LOCAL_STORAGE_KEY } from 'constants/localStorageKey';
import PATHS from 'constants/path';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSelfInfo from 'hooks/useSelfInfo';
import useUserName from 'hooks/useUserName';
import useWeb3 from 'hooks/useWeb3';
import { isConnectWalletModalOpenAtom } from 'store/modalAtom';
import { checkExp, decodeJwt } from 'utils/jwtUtils';
import ActionsCard from './ActionsCard';
import BalanceCard from './BalanceCard';
import QrCodeCard from './QrCodeCard';
import WelcomeCard from './WelcomeCard';
import { SectionContainer, SectionTitle } from '../SectionContainer';

const NameContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const NameEditButton = styled.button``;

export default function WelcomeSection(): JSX.Element | null {
  const [isSetNicknameModalOpen, setIsSetNicknameModalOpen] = useState(false);
  const setIsConnectModalWalletOpen = useSetAtom(isConnectWalletModalOpenAtom);
  const { isConnected, isConnecting } = useWeb3();
  const { selfInfo } = useSelfInfo();
  const navigate = useNavigateWithLang();

  const decodedSignature = useMemo(() => decodeJwt(localStorage.getItem(JWT_LOCAL_STORAGE_KEY)), []);
  const { username } = useUserName(decodedSignature?.senderAddress);

  const openSetNicknameModal = (): void => setIsSetNicknameModalOpen(true);

  const closeSetNicknameModal = (): void => setIsSetNicknameModalOpen(false);

  useEffect(() => {
    if (isConnected) {
      setIsConnectModalWalletOpen(false);
    }
  }, [setIsConnectModalWalletOpen, isConnected]);

  const onClickPreLoginQrCode = (): void => {
    navigate(`/${PATHS.QR_CODE_EARN}`);
  };

  if (isConnected) {
    return (
      <SectionContainer>
        <NameContainer>
          <SectionTitle translationKey="home.username.after-login" values={{ name: selfInfo?.name || '' }} />
          {selfInfo?.name && (
            <NameEditButton onClick={openSetNicknameModal}>
              <Icon icon="edit" size="16px" />
            </NameEditButton>
          )}
        </NameContainer>
        <BalanceCard />
        <ActionsCard />
        <ChangeNicknameModal
          isOpen={isSetNicknameModalOpen}
          onRequestClose={closeSetNicknameModal}
          defaultNickname={selfInfo?.name}
        />
      </SectionContainer>
    );
  }

  if (decodedSignature && !checkExp(decodedSignature?.exp)) {
    return (
      <SectionContainer>
        <SectionTitle translationKey="home.username.after-login" values={{ name: username || '' }} />
        <QrCodeCard onClick={onClickPreLoginQrCode} />
        <ActionsCard />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <SectionTitle translationKey="home.username.before-login" />
      <WelcomeCard disabled={isConnecting} onClick={() => setIsConnectModalWalletOpen(true)} />
      <ActionsCard />
    </SectionContainer>
  );
}
