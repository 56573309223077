import { Wallet, formatUtils } from 'adam-frontend-shared';
import copy from 'copy-to-clipboard';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import backgroundImage from 'assets/decorations/image_wallet_detail_background.svg';
import Button from 'components/Button';
import DebugArea from 'components/DebugArea';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { coffeeDaoWhiteLogo, pseudoBackgroundCSS } from 'components/images';
import { Card } from 'components/layout';
import LocalizedText from 'components/LocalizedText';
import { Modal } from 'components/Modal';
import QrCode from 'components/QrCode';
import Text from 'components/Text';
import useSelfInfo from 'hooks/useSelfInfo';
import useSession from 'hooks/useSession';
import useWeb3 from 'hooks/useWeb3';
import { walletDetailModalAtom, web3Connector } from 'store/web3Atom';
import { toast } from 'utils/toastUtils';

const WalletDetailModal = styled(Modal).attrs({ size: 'small', align: 'center' })`
  padding: 16px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const WalletDetailCard = styled(Card)`
  margin-top: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.4;
    ${pseudoBackgroundCSS(backgroundImage, '285px', '285px')}
  }
`;

const CoffeeDaoLogo = styled.img.attrs({ src: coffeeDaoWhiteLogo })`
  height: 28px;
  margin-bottom: 32px;
  align-self: flex-start;
`;

const Subtitle = styled(LocalizedText).attrs({ category: 'body2' })`
  color: ${({ theme: { color } }) => color.tinted};
  letter-spacing: 5.04px;
  text-transform: uppercase;
`;

const DrinkerName = styled(Text).attrs({ category: 'h2', variant: 'secondary' })``;

const ConnectWalletType = styled(LocalizedText).attrs({ category: 'body2', variant: 'secondary' })`
  opacity: 0.6;
  margin-bottom: 36px;
`;

const AddressQrCode = styled(QrCode)`
  align-self: center;
`;

const CopyAddressButton = styled(Button).attrs({ variant: 'text' })`
  width: 100%;
  height: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const AddressText = styled(Text).attrs({ category: 'body2', variant: 'secondary', weight: 'medium' })`
  opacity: 0.6;
  white-space: nowrap;
`;

const CopyIcon = styled(Icon).attrs({ icon: 'copy', size: '16px', color: 'secondary' })`
  opacity: 0.6;
`;

const DisconnectWalletButton = styled(Button).attrs({ variant: 'text' })`
  padding-top: 16px;
  width: 100%;
`;

const DisconnectWalletText = styled(LocalizedText).attrs({ category: 'body2', variant: 'primary' })``;

export default function WalletDetailManager(): JSX.Element {
  const [isWalletDetailModalOpen, setIsWalletDetailModalOpen] = useAtom(walletDetailModalAtom);
  const { address: eoaAddress, walletType } = useWeb3();
  const { clearSession } = useSession();
  const { selfInfo } = useSelfInfo();
  const { t } = useTranslation();

  const connectedWalletTypeName = walletType ? Wallet.WalletConfigs[walletType]?.name : '';
  const ellipsisAddress = useMemo(() => formatUtils.toEllipsisAddress(eoaAddress), [eoaAddress]);

  const closeWalletDetailModal = (): void => {
    setIsWalletDetailModalOpen(false);
  };

  const onClickDisconnect = async (): Promise<void> => {
    await web3Connector.disconnectEOA(true);
    clearSession();
    closeWalletDetailModal();
  };

  const onClickCopyAddress = (): void => {
    if (eoaAddress) {
      copy(eoaAddress);
      toast(t('text-copied.address'));
    }
  };

  return (
    <WalletDetailModal isOpen={isWalletDetailModalOpen} onRequestClose={closeWalletDetailModal}>
      <Header>
        <IconButton
          icon="close"
          variant="secondary"
          iconSize="16px"
          buttonSize="32px"
          onClick={closeWalletDetailModal}
        />
      </Header>
      <WalletDetailCard>
        <CoffeeDaoLogo />
        <Subtitle translationKey="wallet.detail.subtitle" />
        <DrinkerName>{selfInfo?.name}</DrinkerName>
        <ConnectWalletType
          translationKey="wallet.detail.wallet-type-label"
          values={{ walletTypeName: connectedWalletTypeName }}
        />
        {eoaAddress && <AddressQrCode data={eoaAddress} />}
        <CopyAddressButton onClick={onClickCopyAddress}>
          <AddressText>{ellipsisAddress}</AddressText>
          <CopyIcon />
        </CopyAddressButton>
      </WalletDetailCard>
      <DisconnectWalletButton onClick={onClickDisconnect}>
        <DisconnectWalletText translationKey="wallet.detail.button-disconnect" />
      </DisconnectWalletButton>
      <DebugArea>
        <Button
          variant="text"
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          Clear local storage
        </Button>
      </DebugArea>
    </WalletDetailModal>
  );
}
