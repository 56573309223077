import { Token } from '@adam-vault/adam-sdk';
import { NumberUtils, Web3Utils } from 'adam-frontend-shared';
import { BigNumber } from 'ethers';
import { useSetAtom, Atom, WritableAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { CustomError, CustomErrorType } from 'constants/error/CustomError';
import {
  polygonTokenAtom,
  coffeeDaoTokenAtom,
  coffeeDaoTokenDetailAtom,
  TokenDetail,
  polygonTokenDetailAtom,
  DEFAULT_TOKEN_DETAIL,
} from 'store/tokenAtom';
import { Loadable } from 'utils/atomUtils';
import { toast } from 'utils/toastUtils';
import useLoadableAtom from './useLoadableAtom';

type UseTokenAtomHook = {
  token: Token | undefined;
  decimals?: number;
  name?: string;
  symbol?: string;
  toDisplay: (
    amount: BigNumber | string | number | null | undefined,
    options?: { maxDecimals?: number; shouldTrimTrailingZero?: boolean }
  ) => string;
  toBN: (amount: string | number) => BigNumber | null;
  isLoading: boolean;
};

export default function useTokenAtom(
  tokenAtom: Atom<Loadable<Promise<Token | undefined>>>,
  tokenDetailAtom: WritableAtom<Loadable<TokenDetail>, undefined, void>
): UseTokenAtomHook {
  const { isLoading: isLoadingToken, data: token, error: tokenError } = useLoadableAtom(tokenAtom);
  const {
    isLoading: isLoadingTokenDetail,
    data: tokenDetail,
    error: tokenDetailError,
  } = useLoadableAtom(tokenDetailAtom);
  const reloadTokenDetail = useSetAtom(tokenDetailAtom);

  const { decimals, symbol, name } = tokenDetail || DEFAULT_TOKEN_DETAIL;

  useEffect(() => {
    if (!decimals) {
      reloadTokenDetail();
    }
  }, [decimals, reloadTokenDetail]);

  useEffect(() => {
    if (tokenError || tokenDetailError) {
      // eslint-disable-next-line no-console
      console.error(tokenError || tokenDetailError);
      toast(CustomError[CustomErrorType.FETCH_COFFEE_TOKEN_FAIL].message);
    }
  }, [tokenDetailError, tokenError]);

  const toDisplay = useCallback<
    (
      amount: BigNumber | string | number | null | undefined,
      options?: { maxDecimals?: number; shouldTrimTrailingZero?: boolean }
    ) => string
  >(
    (amount, { maxDecimals = 2, shouldTrimTrailingZero = true } = { maxDecimals: 2, shouldTrimTrailingZero: true }) => {
      if (!token || decimals === undefined || !Number.isInteger(decimals) || !amount) {
        return '';
      }
      return NumberUtils.toAmountString(amount.toString(), { decimals, maxDecimals, shouldTrimTrailingZero });
    },
    [decimals, token]
  );

  const toBN = useCallback(
    (amount: string | number) => {
      if (!token || decimals === undefined || !Number.isInteger(decimals) || !amount) {
        return null;
      }

      return Web3Utils.toUnitBN(amount, decimals);
    },
    [decimals, token]
  );

  return {
    token,
    decimals,
    symbol,
    name,
    toDisplay,
    toBN,
    isLoading: isLoadingToken && isLoadingTokenDetail,
  };
}

export function usePolygonToken(): UseTokenAtomHook {
  return useTokenAtom(polygonTokenAtom, polygonTokenDetailAtom);
}

export function useCoffeeDaoToken(): UseTokenAtomHook {
  return useTokenAtom(coffeeDaoTokenAtom, coffeeDaoTokenDetailAtom);
}
