import { Campaign, CoffeeDao } from '@adam-vault/adam-sdk-coffeedao';
import { useAsyncMemo } from 'adam-frontend-shared';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import coffeeWithStars from 'assets/images/image_coffee_with_stars.svg';
import ErrorBlock from 'components/ErrorBlock';
import { bloomTealGreenBigFilledImage, successModalBackground } from 'components/images';
import LocalizedText from 'components/LocalizedText';
import { ModalHeader } from 'components/Modal';
import useClaimCampaignReward from 'hooks/useClaimCampaignReward';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import useLanguage from 'hooks/useLanguage';
import useSelfInfo from 'hooks/useSelfInfo';
import useSetIsWelcomeRewardModalOpen from 'hooks/useSetIsWelcomeRewardModalOpen';
import useToastError from 'hooks/useToastError';
import { useCoffeeDaoToken } from 'hooks/useToken';
import { isConnectWalletModalOpenAtom } from 'store/modalAtom';
import { gaClassName } from 'utils/gtmEventUtils';
import AuthenticateBlock from './AuthenticateBlock';
import ContentBlock from './ContentBlock';
import { CampaignStep } from './formStep';
import LoadingView from './LoadingView';
import SuccessBlock from './SuccessBlock';
import SuccessBlockArbor from './SuccessBlockArbor';

const BloomBackground = styled.img.attrs({ src: bloomTealGreenBigFilledImage })`
  position: absolute;
  top: min(260px, 100% + 88px - 200px);
  left: 50%;
  width: calc(100% + 88px);
  transform: translateY(-100%) translateX(-50%);
  z-index: -1;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  overflow-y: auto;
`;

const SuccessContainer = styled(Container)`
  background-image: url(${successModalBackground});
  background-size: cover;
`;

const CampaignImage = styled.img.attrs({ src: coffeeWithStars })`
  margin-top: 16px;
`;

const FootnoteContainer = styled.div`
  margin-top: auto;
  opacity: 0.4;
  text-align: center;
`;

interface Props {
  onCloseModal: (isOpenWelcomeRewardModal?: boolean) => void;
  isArborCampaign?: boolean;
  campaignId: string | null;
  campaignUniqueCode: string | null;
}

export default function ClaimView(props: Props): JSX.Element | null {
  const { onCloseModal, isArborCampaign, campaignId, campaignUniqueCode } = props;
  const [step, setStep] = useState<CampaignStep>(CampaignStep.CLAIM_WELCOME_REWARD);
  const [isLoadingLanguage, setIsLoadingLanguage] = useState<boolean>(true);
  const { languagePath, updateLanguage } = useLanguage();
  const { toDisplay } = useCoffeeDaoToken();
  const setIsWelcomeRewardModalOpen = useSetIsWelcomeRewardModalOpen();
  const setIsConnectWalletModalOpen = useSetAtom(isConnectWalletModalOpenAtom);
  const { isLoading: isLoadingSelfInfo, selfInfo, setSelfInfo } = useSelfInfo();
  const { isAuthenticated } = useIsAuthenticated();

  const [campaign, { isLoading: isLoadingCampaign, error }] = useAsyncMemo<Campaign | undefined>(
    async () => {
      if (!campaignId) {
        return undefined;
      }

      return CoffeeDao.getCampaign(process.env.REACT_APP_ENV, campaignId);
    },
    [campaignId],
    undefined
  );

  const closeButtonClassName = gaClassName('campaign-modal-close' + (isArborCampaign ? '-arbor' : ''));

  const Footnote = useCallback(
    () => (
      <FootnoteContainer>
        {campaignUniqueCode && (
          <LocalizedText
            category="caption"
            translationKey="campaign.footnote-unique-code"
            values={{ campaignUniqueCode }}
          />
        )}
        {campaignId && (
          <LocalizedText category="caption" translationKey="campaign.footnote-campaign-id" values={{ campaignId }} />
        )}
      </FootnoteContainer>
    ),
    [campaignId, campaignUniqueCode]
  );

  useEffect(() => {
    if (isLoadingCampaign || !campaign) {
      return;
    }

    if (campaign && new Date(campaign.endedAt).getTime() < Date.now()) {
      setStep(CampaignStep.EXPIRED);
    } else if (selfInfo?.campaigns.some((c) => c.id === campaign?.id)) {
      setStep(CampaignStep.COMPLETE);
    } else if (!campaign.needKyc || (!isLoadingSelfInfo && selfInfo?.isMobileVerified)) {
      setStep(CampaignStep.CLAIM_CAMPAIGN_REWARD);
    } else {
      setStep(CampaignStep.CLAIM_WELCOME_REWARD);
    }
  }, [campaign, isLoadingCampaign, isLoadingSelfInfo, selfInfo]);

  const {
    isLoading: isLoadingCampaignReward,
    claimCampaignReward,
    error: campaignRewardError,
  } = useClaimCampaignReward({
    onClaimRewardSuccess() {
      if (campaign) {
        setStep(CampaignStep.COMPLETE);
        setSelfInfo((prev) => (prev ? { ...prev, campaigns: [...prev.campaigns, campaign] } : null));
      }
    },
  });

  useEffect(() => {
    if (isArborCampaign) {
      updateLanguage('zh');
    } else {
      updateLanguage(languagePath);
    }
    setIsLoadingLanguage(false);
  }, [isArborCampaign, languagePath, updateLanguage]);

  useToastError(campaignRewardError, 'claimCampaignRewardError');

  const onAuthenticate = (): void => {
    setIsConnectWalletModalOpen(true);
  };

  const onClaimWelcomeReward = (): void => {
    setIsWelcomeRewardModalOpen(true);
  };

  const onClaimCampaignReward = async (): Promise<void> => {
    if (campaign) {
      await claimCampaignReward(campaign.id, toDisplay(campaign.amount), campaignUniqueCode || undefined);
    }
  };

  if (isLoadingCampaign || isLoadingLanguage) {
    return <LoadingView />;
  }

  if (error || !campaign || (campaign.requiresCode && !campaignUniqueCode)) {
    return (
      <Container>
        <ModalHeader logo="primary" closeButtonClassName={closeButtonClassName} />
        <ErrorBlock onBack={onCloseModal} />
      </Container>
    );
  }

  if (step === CampaignStep.EXPIRED) {
    return (
      <Container>
        <BloomBackground />
        <ModalHeader
          logo="secondary"
          showArborLogo={isArborCampaign}
          onClose={onCloseModal}
          closeButtonClassName={closeButtonClassName}
        />
        <LocalizedText category="h1" variant="secondary" translationKey="campaign.title" />
        <CampaignImage />
        <AuthenticateBlock title={campaign.title} description={campaign.description} isExpired />
        <Footnote />
      </Container>
    );
  }

  if (step === CampaignStep.COMPLETE) {
    return (
      <SuccessContainer>
        <ModalHeader
          logo="secondary"
          showArborLogo={isArborCampaign}
          onClose={onCloseModal}
          closeButtonClassName={closeButtonClassName}
        />
        {isArborCampaign ? (
          <SuccessBlockArbor
            onComplete={onCloseModal}
            campaignRewardAmount={toDisplay(campaign?.amount)}
            isWelcomeRewardClaimed={selfInfo?.isWelcomeRewardClaimed}
          />
        ) : (
          <SuccessBlock onComplete={onCloseModal} campaignRewardAmount={toDisplay(campaign?.amount)} />
        )}
      </SuccessContainer>
    );
  }

  return (
    <Container>
      <BloomBackground />
      <ModalHeader
        logo="secondary"
        showArborLogo={isArborCampaign}
        onClose={onCloseModal}
        closeButtonClassName={closeButtonClassName}
      />
      <LocalizedText
        category="h1"
        variant="secondary"
        translationKey={isArborCampaign ? 'campaign.title-arbor' : 'campaign.title'}
      />
      <CampaignImage />
      {isAuthenticated ? (
        <ContentBlock
          isArborCampaign={isArborCampaign}
          title={campaign.title}
          step={step}
          isMobileRegistrationRequired={campaign.needKyc}
          isLoadingWelcomeReward={isLoadingSelfInfo}
          isLoadingCampaignReward={isLoadingSelfInfo || isLoadingCampaignReward}
          onClaimCampaignReward={onClaimCampaignReward}
          onClaimWelcomeReward={onClaimWelcomeReward}
        />
      ) : (
        <AuthenticateBlock
          title={campaign.title}
          description={campaign.description}
          onAuthenticate={onAuthenticate}
          isArborCampaign={isArborCampaign}
        />
      )}
      <Footnote />
    </Container>
  );
}
