import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import LogoCoffeeDao from 'assets/images/logo_coffee_dao.svg';
import IconButton from 'components/IconButton';
import { HEADER_HEIGHT, HEADER_ICON_SIZE, HEADER_PADDING } from 'constants/dimension';
import { JWT_LOCAL_STORAGE_KEY } from 'constants/localStorageKey';
import PATHS from 'constants/path';
import UserType from 'constants/userType';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import useLanguage from 'hooks/useLanguage';
import useNavigateWithLang from 'hooks/useNavigateWithLang';
import useSession from 'hooks/useSession';
import useSetIsWalletDetailModalOpen from 'hooks/useSetIsWalletDetailModalOpen';
import useUserType from 'hooks/useUserType';
import { isConnectWalletModalOpenAtom } from 'store/modalAtom';
import { web3Connector } from 'store/web3Atom';
import { gaClassName } from 'utils/gtmEventUtils';
import { checkExp, decodeJwt } from 'utils/jwtUtils';
import DebugArea from './DebugArea';
import LocalizedText from './LocalizedText';
import { UserAvatar } from './UserAvatar';

const Container = styled.div<{ isScrolling: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.header};
  background-color: ${({ theme }) => theme.color.headerBackground};
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  padding: ${HEADER_PADDING}px;
  margin-bottom: 16px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  transition: box-shadow 0.2s ease-in-out;

  ${({ isScrolling }) =>
    isScrolling &&
    css`
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
    `}
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const LogoButton = styled.button`
  display: flex;
`;

const LoginButton = styled.button`
  width: 80px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.color.primary};
`;

const LogoImg = styled.img.attrs({ src: LogoCoffeeDao })``;

const IconButtonSection = styled.div`
  display: flex;
  gap: 8px;
`;

interface Props {
  noScanner?: boolean;
}

export default function Header({ noScanner }: Props): JSX.Element {
  const [isScrolling, setIsScrolling] = useState(false);
  const navigate = useNavigateWithLang();
  const setIsWalletDetailModalOpen = useSetIsWalletDetailModalOpen();
  const setIsConnectWalletModalOpen = useSetAtom(isConnectWalletModalOpenAtom);
  const { isAuthenticated } = useIsAuthenticated();
  const { toggleDebug } = useLanguage();
  const { clearSession } = useSession();
  const userType = useUserType();
  const containerRef = useRef<HTMLDivElement>(null);

  const decodedSignature = useMemo(() => decodeJwt(localStorage.getItem(JWT_LOCAL_STORAGE_KEY)), []);

  useEffect(() => {
    const pageContainer = containerRef.current?.parentElement;
    if (!pageContainer) {
      return;
    }

    const onScroll = (): void => {
      setIsScrolling(pageContainer.scrollTop > HEADER_HEIGHT);
    };

    pageContainer.addEventListener('scroll', onScroll);

    return (): void => {
      pageContainer.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onClickLogo = (): void => {
    navigate('/');
  };

  const onClickSettings = (): void => {
    navigate(`/${PATHS.SHOP}/${PATHS.SETTINGS}`);
  };

  const onClickScanner = (): void => {
    navigate(`/${PATHS.SCANNER}`);
  };

  const onClickWalletDetail = (): void => {
    setIsWalletDetailModalOpen(true);
  };

  const onClickLogin = (): void => {
    setIsConnectWalletModalOpen(true);
  };

  const onClickLogout = async (): Promise<void> => {
    await web3Connector.disconnectEOA(true);
    clearSession();
  };

  return (
    <Container isScrolling={isScrolling} ref={containerRef}>
      <LeftSection>
        <LogoButton onClick={onClickLogo} className={gaClassName('header-home')}>
          <LogoImg />
        </LogoButton>
      </LeftSection>
      <IconButtonSection>
        {userType === UserType.COFFEE_SHOP ? (
          <>
            <IconButton
              icon="settings"
              iconSize="24px"
              iconColor="primary"
              buttonSize="40px"
              buttonColor="interactive"
              className={gaClassName('header-scanner')}
              onClick={onClickSettings}
              withoutBorder
            />
            <IconButton
              icon="logout"
              iconSize="24px"
              iconColor="primary"
              buttonSize="40px"
              buttonColor="interactive"
              className={gaClassName('header-scanner')}
              onClick={onClickLogout}
              withoutBorder
            />
          </>
        ) : !isAuthenticated && !checkExp(decodedSignature?.exp) ? (
          <LoginButton onClick={onClickLogin}>
            <LocalizedText category="body2" translationKey="wallet.connect.title" />
          </LoginButton>
        ) : (
          <>
            <DebugArea>
              <IconButton
                onClick={toggleDebug}
                variant="primary"
                icon="transaction"
                iconSize={`${HEADER_ICON_SIZE}px`}
              />
            </DebugArea>
            {isAuthenticated && (
              <IconButton
                type="button"
                onClick={onClickWalletDetail}
                variant="primary"
                buttonPadding="1px"
                className={gaClassName('header-account-info')}
              >
                <UserAvatar size={32} />
              </IconButton>
            )}
            {!noScanner && (
              <IconButton
                onClick={onClickScanner}
                variant="primary"
                icon="scan"
                iconSize={`${HEADER_ICON_SIZE}px`}
                className={gaClassName('header-scanner')}
              />
            )}
          </>
        )}
      </IconButtonSection>
    </Container>
  );
}
