import { GTMUtils } from 'adam-frontend-shared';

export const enum GTMCustomEvent {
  SocialLoginRequested = 'social_login_requested',
  SocialLoginConnected = 'social_login_connected',
  WalletConnected = 'wallet_connected',
  EOAConnected = 'eoa_connected',
  SignatureRequested = 'signature_requested',
  SignatureSigned = 'signature_signed',
  SignatureVerified = 'signature_verified',
  OTPVerified = 'otp_verified',
  WelcomeRewardProcessing = 'welcome_reward_processing',
  WelcomeRewardCompleted = 'welcome_reward_completed',
  SpendTokenProcessing = 'spend_token_processing',
  SpendTokenCompleted = 'spend_token_completed',
  CampaignRegistrationProcessing = 'campaign_registration_processing',
  CampaignRegistrationCompleted = 'campaign_registration_completed',
  ReferralRewardProcessing = 'referral_reward_processing',
  ReferralRewardCompleted = 'referral_reward_completed',
  ScanQRCodeSuccess = 'scan_qr_code_success',
}

export function sendGTMEvent(event: GTMCustomEvent, label: string, value: string): void {
  GTMUtils.sendEvent({
    event: `Event - Custom - ${event}`,
    payload: {
      eventProps: {
        category: 'Custom',
        action: event,
        label,
        value,
      },
    },
  });
}

export function gaClassName(tag: string): string {
  return `ga-button__${tag.replace('ga-button__', '')}`;
}
