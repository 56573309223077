import { SUBSIDY_TRANSACTION_STATUS } from '@adam-vault/adam-sdk-coffeedao';
import { useCallback, useState } from 'react';
import { CustomError, CustomErrorType } from 'constants/error/CustomError';
import { GTMCustomEvent, sendGTMEvent } from 'utils/gtmEventUtils';
import useCoffeeDao from './useCoffeeDao';
import useCoffeeDaoClient from './useCoffeeDaoClient';

interface Props {
  onClaimRewardSuccess: () => void;
}

interface Return {
  claimCampaignReward: (
    campaignId: string,
    campaignRewardAmount: string,
    campaignUniqueCode?: string
  ) => Promise<SUBSIDY_TRANSACTION_STATUS>;
  isLoading: boolean;
  error: Error | undefined;
}

export default function useClaimCampaignReward(props: Props): Return {
  const { onClaimRewardSuccess } = props;
  const coffeeDaoClient = useCoffeeDaoClient();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const coffeeDao = useCoffeeDao();

  const claimCampaignReward = useCallback(
    async (campaignId: string, campaignRewardAmount: string, code?: string) => {
      if (!coffeeDao || !coffeeDaoClient) {
        setError(CustomError[CustomErrorType.GENERIC_ERROR] as Error);
        return SUBSIDY_TRANSACTION_STATUS.CANCELED;
      }
      try {
        setIsLoading(true);
        sendGTMEvent(GTMCustomEvent.CampaignRegistrationProcessing, 'amount', campaignRewardAmount);
        const campaignRewardTransaction = await coffeeDaoClient.request(
          coffeeDao.createCampaignGiftTxn({ campaignId, code })
        );
        sendGTMEvent(GTMCustomEvent.CampaignRegistrationCompleted, 'amount', campaignRewardAmount);
        setIsLoading(false);
        onClaimRewardSuccess();
        return campaignRewardTransaction.status;
      } catch (err) {
        setIsLoading(false);
        setError(err as Error);
        return SUBSIDY_TRANSACTION_STATUS.CANCELED;
      }
    },
    [coffeeDao, coffeeDaoClient, onClaimRewardSuccess]
  );

  return { claimCampaignReward, isLoading, error };
}
