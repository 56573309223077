import { Env } from '@adam-vault/adam-sdk';
import { Chain, Wallet } from 'adam-frontend-shared';
import { ethers } from 'ethers';
import { Setter, atom } from 'jotai';
import { WALLET_TYPE_LOCAL_STORAGE_KEY } from 'constants/localStorageKey';

const { CHAIN_INFO, DEFAULT_CHAIN_ID } = Chain;

type NetworkInfo = {
  chainId: Chain.ChainId;
  ensAddress?: string;
  name: string;
};

export const DEFAULT_PROVIDER = new ethers.providers.JsonRpcProvider(
  CHAIN_INFO[DEFAULT_CHAIN_ID].envParam.privateRpcUrl
);

const connectionAtom = atom<Wallet.WalletConnection>('disconnected');

const walletsAtom = atom<Partial<Wallet.WalletAdapterMap>>(
  Wallet.createWalletAdapters({
    [Wallet.WalletType.WEB3AUTH]: {
      isAvailable: true,
      clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
      network: process.env.REACT_APP_WEB3AUTH_NETWORK,
      redirectWithHash: true,
      enableLogging: process.env.REACT_APP_ENV === Env.DEV,
    },
    [Wallet.WalletType.METAMASK]: {
      isAvailable: true,
    },
    [Wallet.WalletType.WALLET_CONNECT]: {
      isAvailable: true,
      projectId: process.env.REACT_APP_WC_PROJECT_ID || '',
    },
  })
);

export const web3Connector = new Wallet.Web3Connector(
  Wallet.createWalletAdapters({
    [Wallet.WalletType.WEB3AUTH]: {
      isAvailable: true,
      clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
      network: process.env.REACT_APP_WEB3AUTH_NETWORK,
      redirectWithHash: true,
      enableLogging: process.env.REACT_APP_ENV === Env.DEV,
    },
    [Wallet.WalletType.METAMASK]: {
      isAvailable: true,
    },
    [Wallet.WalletType.WALLET_CONNECT]: {
      isAvailable: true,
      projectId: process.env.REACT_APP_WC_PROJECT_ID || '',
    },
  }),
  {
    defaultChainId: DEFAULT_CHAIN_ID,
    defaultWalletType: localStorage.getItem(WALLET_TYPE_LOCAL_STORAGE_KEY) as Wallet.WalletType | null,
  }
);

const providerAtom = atom<Wallet.Provider | null>(null);

const signerAtom = atom<Wallet.Signer | null>(null);

const chainIdAtom = atom<number | null>(DEFAULT_CHAIN_ID);

const addressAtom = atom<string | null>(null);

const errorAtom = atom<Error | null>(null);

const hasReconnectTriedAtom = atom<boolean>(false);

export const isEOAAddressUpdatedAtom = atom<boolean>(false);

const onEthereumProviderAccountChanged =
  (address: string, set: Setter) =>
  (accounts: string[]): void => {
    if (accounts.length === 0) {
      return;
    }
    const newAddress = accounts[0];
    if (newAddress !== address) {
      set(isEOAAddressUpdatedAtom, true);
    } else {
      set(isEOAAddressUpdatedAtom, false);
    }
  };

const readonlyWeb3Atom = atom<Wallet.Web3>((get) => ({
  wallets: get(walletsAtom),
  connection: get(connectionAtom),
  chainId: get(chainIdAtom),
  walletType: localStorage.getItem(WALLET_TYPE_LOCAL_STORAGE_KEY) as Wallet.WalletType | null,
  provider: get(providerAtom),
  signer: get(signerAtom),
  address: get(addressAtom),
  error: get(errorAtom),
  hasReconnectTried: get(hasReconnectTriedAtom),
}));

export const web3Atom = atom<Wallet.Web3 & Wallet.Web3ConnectionStatus, Wallet.Web3Setter, Promise<void>>(
  (get) => {
    const web3 = get(readonlyWeb3Atom);
    return {
      ...web3,
      isConnected: web3.connection === 'connected',
      isConnecting: web3.connection === 'connecting',
      isDisconnected: web3.connection === 'disconnected',
      isDisconnecting: web3.connection === 'disconnecting',
    };
  },
  async (get, set, setter) => {
    const web3 = typeof setter === 'function' ? setter(get(readonlyWeb3Atom)) : setter;
    const { connection, wallets, walletType, provider, signer, address, chainId, error, hasReconnectTried } = web3;

    // eslint-disable-next-line no-console
    console.log(
      'web3Atom',
      JSON.stringify(
        {
          connection,
          chainId,
          walletType,
          address,
          provider: provider?.network,
          error,
          hasReconnectTried,
        },
        null,
        2
      )
    );

    // Update wallets
    if (wallets !== undefined) {
      set(walletsAtom, wallets);
    }

    // Update signer related atoms
    if (signer !== undefined) {
      set(signerAtom, signer);
    }
    const currentSigner = get(signerAtom);

    // Update address
    if (address !== undefined) {
      set(addressAtom, address);
    } else if (currentSigner) {
      try {
        set(addressAtom, await currentSigner.getAddress());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('EOA not signed', e);
        set(addressAtom, null);
      }
    }

    // Update chainId
    if (chainId !== undefined) {
      set(chainIdAtom, chainId);
    } else if (currentSigner) {
      try {
        set(chainIdAtom, await currentSigner.getChainId());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('EOA not signed', e);
        set(addressAtom, null);
      }
    }

    // Update provider
    if (provider !== undefined) {
      if (provider) {
        set(providerAtom, provider);
        const currentProvider = get(providerAtom);
        currentProvider?.getSigner();
        currentProvider?.on('network', (newNetwork: NetworkInfo) => {
          // When a Provider makes its initial connection, it emits a "network"
          // event with a null oldNetwork along with the newNetwork. So, if the
          // oldNetwork exists, it represents a changing network
          set(chainIdAtom, newNetwork.chainId);
        });
      } else {
        const currentProvider = get(providerAtom);
        currentProvider?.off('network');
        set(providerAtom, provider);
      }
    } else if (!!window?.ethereum?.removeListener) {
      window.ethereum.removeListener('accountsChanged', onEthereumProviderAccountChanged(address ?? '', set));
    }

    // Update walletType
    if (walletType !== undefined) {
      if (walletType) {
        localStorage.setItem(WALLET_TYPE_LOCAL_STORAGE_KEY, walletType);
      } else {
        localStorage.removeItem(WALLET_TYPE_LOCAL_STORAGE_KEY);
      }
    }
    // Update connection
    if (connection !== undefined) {
      set(connectionAtom, connection);
    }
    // Update error
    if (error !== undefined) {
      set(errorAtom, error);
    }
    // Update isReconnectTried
    if (hasReconnectTried !== undefined) {
      set(hasReconnectTriedAtom, hasReconnectTried);
    }
  }
);

export const walletDetailModalAtom = atom<boolean>(false);
