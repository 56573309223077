import { Wallet } from 'adam-frontend-shared';
import copy from 'copy-to-clipboard';
import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ActionListItem from 'components/ActionListItem';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import LoadingSpinner from 'components/Loading';
import LocalizedText from 'components/LocalizedText';
import { ModalCard } from 'components/Modal';
import { SignatureRequiredModal } from 'components/signature';
import { REFERRAL_CODE_PARAM_KEY, WELCOME_REWARD_PARAM_KEY } from 'constants/searchParams';
import useClaimReferralReward from 'hooks/useClaimReferralReward';
import useReferral from 'hooks/useReferral';
import useToastError from 'hooks/useToastError';
import { useCoffeeDaoToken } from 'hooks/useToken';
import useWeb3 from 'hooks/useWeb3';
import { gaClassName } from 'utils/gtmEventUtils';
import { toast } from 'utils/toastUtils';
import { nativeShareOrCopy } from 'utils/userAgentUtils';

const ReferralModalCard = styled(ModalCard)`
  padding-bottom: 0;
`;

const ReferralDetailContainer = styled.div`
  margin-top: 36px;
  margin-left: -36px;
  margin-right: -36px;
  width: calc(100% + 72px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.color.tinted};
  padding: 24px 36px;
  border-radius: 20px;
`;

const ReferralDetail = styled.div`
  display: flex;
  width: 100%;
`;

export default function ReferralRewardBlock(): JSX.Element {
  const { t } = useTranslation();
  const { walletType } = useWeb3();
  const { isLoading, referralCode, referrals, error, reloadReferrals } = useReferral();
  const { toDisplay } = useCoffeeDaoToken();

  const { referralSuccessCount, referralRewardAmount } = useMemo(() => {
    let rewardAmount = BigNumber.from(0);

    for (const referral of referrals) {
      rewardAmount = rewardAmount.add(BigNumber.from(referral.status === 'pending' ? referral.rewardAmount : 0));
    }

    return { referralSuccessCount: referrals.length, referralRewardAmount: toDisplay(rewardAmount) };
  }, [referrals, toDisplay]);

  const {
    claimReferralReward,
    isLoading: isClaimLoading,
    error: claimError,
  } = useClaimReferralReward({
    onClaimRewardSuccess() {
      reloadReferrals();
      toast(t('referral.referral-reward-message', { amount: referralRewardAmount }));
    },
  });

  useToastError(error);
  useToastError(claimError ? new Error(claimError) : null);

  const isReferralCodeMissing = !referralCode;

  const onClickCopyReferralCode = (): void => {
    if (referralCode) {
      copy(referralCode);
      toast(t('text-copied.referral-code'));
    }
  };

  const onClickShareReferralLink = (): void => {
    if (referralCode) {
      const text = t('referral.referral-copy-message');
      const link = `${window.location.origin}/#/?${WELCOME_REWARD_PARAM_KEY}=open&${REFERRAL_CODE_PARAM_KEY}=${referralCode}`;
      nativeShareOrCopy({ text, link });
    }
  };

  const onClickClaimReferralReward = (): void => {
    const referralIds = referrals.filter((referral) => referral.status === 'pending').map((referral) => referral.id);
    if (referralIds.length > 0) {
      claimReferralReward(referralIds, referralRewardAmount);
    }
  };

  return (
    <>
      <ReferralModalCard image="coin">
        <ModalCard.Heading translationKey="referral.title" />
        <ModalCard.Description translationKey="referral.description" />
        <ActionListItem
          action={
            isLoading || isReferralCodeMissing ? (
              <LoadingSpinner width={32} height={32} />
            ) : (
              <IconButton
                variant="secondary"
                icon="copy"
                buttonSize="40px"
                iconSize="24px"
                onClick={onClickCopyReferralCode}
                disabled={!Boolean(referralCode)}
                className={gaClassName('referral-modal-copy-code')}
              />
            )
          }
        >
          <ActionListItem.Label translationKey="referral.referral-code-label" />
          <ActionListItem.Value>{referralCode}</ActionListItem.Value>
        </ActionListItem>
        <ModalCard.Action>
          <Button
            variant="secondary"
            onClick={onClickShareReferralLink}
            disabled={isLoading || isReferralCodeMissing}
            className={gaClassName('referral-modal-share-code')}
          >
            <LocalizedText category="body1" translationKey="referral.button-refer" />
          </Button>
        </ModalCard.Action>
        <ReferralDetailContainer>
          <ReferralDetail>
            <ActionListItem tinted>
              <ActionListItem.Label translationKey="referral.referral-count-label" />
              <ActionListItem.Value>{referralSuccessCount}</ActionListItem.Value>
            </ActionListItem>
            <ActionListItem tinted>
              <ActionListItem.Label translationKey="referral.referral-reward-label" />
              <ActionListItem.Value>{referralRewardAmount} $COFFEE</ActionListItem.Value>
            </ActionListItem>
          </ReferralDetail>
          {isClaimLoading ? (
            <LoadingSpinner width={32} height={32} />
          ) : (
            <Button
              onClick={onClickClaimReferralReward}
              disabled={parseFloat(referralRewardAmount) <= 0}
              className={gaClassName('referral-modal-claim-reward')}
            >
              <LocalizedText category="body1" translationKey="referral.button-claim" />
            </Button>
          )}
        </ReferralDetailContainer>
      </ReferralModalCard>
      <ModalCard.Footnote translationKey="referral.footnote" />
      <SignatureRequiredModal isOpen={isClaimLoading && walletType !== Wallet.WalletType.WEB3AUTH} />
    </>
  );
}
