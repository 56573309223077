import { useAsyncMemo } from 'adam-frontend-shared';
import useCoffeeShops from './useCoffeeShopList';
import useGetUserName from './useGetUserName';

export default function useUserName(address: string | undefined): { username: string; isLoading: boolean } {
  const getUserName = useGetUserName();
  const { coffeeShops } = useCoffeeShops();

  const [username, { isLoading }] = useAsyncMemo<string>(
    async () => {
      if (!address) {
        return '';
      }

      const coffeeShopFound = coffeeShops.find((coffeeShop) => coffeeShop.eoaAddress === address);
      if (coffeeShopFound) {
        return coffeeShopFound.name;
      }

      return getUserName(address);
    },
    [address],
    ''
  );

  return {
    username,
    isLoading,
  };
}
