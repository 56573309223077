import { GTMUtils } from 'adam-frontend-shared';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { Suspense, useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import RoutesComponent from 'components/navigation/HashRoute';
import SuspenseLoading from 'components/SuspenseLoading';
import { LanguageInitOptions } from 'constants/languages';
import { CoffeeDaoClientProvider } from 'context/CoffeeDaoClient';
import theme from 'theme/default';
import GlobalStyle from 'theme/GlobalStyle';
import * as FacebookPixelUtils from 'utils/facebookPixelUtils';
import { calculateWindowHeight } from 'utils/scrollUtils';

require('@adam-vault/adam-sdk-coffeedao');

// Can reference supported languages from "LanguageKey" in "../constants/languages"
i18n.use(I18nextBrowserLanguageDetector).use(initReactI18next).init(LanguageInitOptions);

GTMUtils.init();
FacebookPixelUtils.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);

export default function App(): JSX.Element {
  useEffect(() => {
    calculateWindowHeight();

    const onResize = (): void => {
      setTimeout(() => {
        calculateWindowHeight();
      }, 350);
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  if (process.env.REACT_APP_GIT_REF && process.env.REACT_APP_GIT_REF !== localStorage.getItem('gitRef')) {
    localStorage.clear();
    localStorage.setItem('gitRef', process.env.REACT_APP_GIT_REF || '');
    window.location.reload();
    return <SuspenseLoading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CoffeeDaoClientProvider>
        <GlobalStyle />
        <Suspense fallback={<SuspenseLoading />}>
          <HashRouter>
            <RoutesComponent />
          </HashRouter>
        </Suspense>
      </CoffeeDaoClientProvider>
    </ThemeProvider>
  );
}
