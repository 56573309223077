import { StylelessInput as Input, StylelessInputProps as InputProps } from 'adam-frontend-shared';
import { useCallback } from 'react';
import styled from 'styled-components';
import ErrorMessage, { Props as ErrorMessageProps } from 'components/form/ErrorMessage';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { CountryDialCodeMap, CountryDialCode, CountryDialCodes } from 'constants/countryDialCodes';
import Dropdown from './Dropdown';

interface Props extends InputProps, ErrorMessageProps {
  countryCodeName?: string;
  onReset?: () => void;
}

interface StyledInputProps {
  hasError?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const InputContainer = styled.div<StyledInputProps>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 40px;
  width: 100%;
  height: 48px;
  padding: 0 24px;
  gap: 16px;
  background-color: ${({ theme: { color } }) => color.dropdownBackground};
  border: ${({ theme: { color }, hasError }) => (hasError ? `1px solid ${color.error}` : '')};
`;

const StyledInput = styled(Input)<InputProps>`
  height: 100%;
  padding-right: 24px;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${({ theme: { color } }) => color.textInputFont};

  ::placeholder {
    color: ${({ theme: { color } }) => color.textInputPlaceHolder};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 4px;
  top: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.secondary};
  display: grid;
  place-items: center;
`;

export default function PhoneNumberInputField(props: Props): JSX.Element {
  const { errorMessage, countryCodeName, onReset, ...inputProps } = props;

  const renderCustomButtonContent = useCallback(
    (dialCode: CountryDialCode['dialCode']): JSX.Element => (
      <Text category="body2">{dialCode ? `+${dialCode}` : ''}</Text>
    ),
    []
  );

  const renderItem = useCallback((dialCode: CountryDialCode['dialCode']): JSX.Element => {
    const item = CountryDialCodeMap[dialCode];
    return <Text category="body2">{`${item.country} (${item.dialCode})`}</Text>;
  }, []);

  return (
    <Container>
      <InputContainer hasError={!!errorMessage}>
        {countryCodeName && (
          <Dropdown<CountryDialCode['dialCode']>
            {...inputProps}
            items={CountryDialCodes}
            name={countryCodeName}
            renderCustomButtonContent={renderCustomButtonContent}
            renderItem={renderItem}
            width={200}
          />
        )}
        <StyledInput inputMode="numeric" pattern="[0-9]*" type="text" {...inputProps} />
        <CloseButton onClick={onReset}>
          <Icon icon="close" size="16px" color="primary" />
        </CloseButton>
      </InputContainer>
      {!!errorMessage && <ErrorMessage errorMessage={errorMessage} contrast />}
    </Container>
  );
}
