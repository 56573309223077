import { StylelessInput as Input, StylelessInputProps as InputProps } from 'adam-frontend-shared';
import styled from 'styled-components';
import ErrorMessage, { Props as ErrorMessageProps } from 'components/form/ErrorMessage';

export interface Props extends InputProps, ErrorMessageProps {}

const Container = styled.div``;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-left: 24px;
`;

interface StyledInputProps {
  hasError?: boolean;
}

const StyledInput = styled(Input)<StyledInputProps>`
  border-radius: 40px;
  width: 100%;
  height: 48px;
  padding: 14px 24px;
  background-color: ${({ theme: { color } }) => color.textInputBackground};

  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${({ theme: { color } }) => color.textInputFont};
  border: ${({ theme: { color }, hasError }) => (hasError ? `1px solid ${color.error}` : '')};

  ::placeholder {
    color: ${({ theme: { color } }) => color.textInputPlaceHolder};
  }
`;

export default function TextInputField(props: Props): JSX.Element {
  const { className, errorMessage, ...inputProps } = props;
  return (
    <Container className={className}>
      <StyledInput hasError={!!errorMessage} {...inputProps} />
      {!!errorMessage && <StyledErrorMessage errorMessage={errorMessage} />}
    </Container>
  );
}
