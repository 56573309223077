import { Chain, Wallet } from 'adam-frontend-shared';
import { useAtom } from 'jotai';
import { web3Atom } from 'store/web3Atom';

export type Web3Params = {
  platformSelectedChainId: Chain.ChainId;
  web3: Wallet.Web3;
  setWeb3: (updatedWeb3: Wallet.Web3Setter) => Promise<void>;
};

export default function useWeb3(): Wallet.Web3 &
  Wallet.Web3ConnectionStatus & { setWeb3: (updatedWeb3: Wallet.Web3Setter) => Promise<void> } {
  const [web3, setWeb3] = useAtom(web3Atom);

  return { ...web3, setWeb3 };
}
