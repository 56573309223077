import { useForm, Yup } from 'adam-frontend-shared';
import { useMemo } from 'react';
import * as yup from 'yup';
import Button from 'components/Button';
import AmountTextInputField from 'components/form/AmountTextInputField';
import LocalizedText from 'components/LocalizedText';
import { ModalCard } from 'components/Modal';
import useCoffeeDaoTokenBalance from 'hooks/useCoffeeDaoTokenBalance';
import { gaClassName } from 'utils/gtmEventUtils';

export interface Props {
  spendAmount: string;
  onConfirm: (amount: string) => void;
  onCancel: () => void;
}

interface EditSpendAmountFormData {
  amount: string;
}

export default function EditAmountBlock({ spendAmount, onConfirm, onCancel }: Props): JSX.Element {
  const { balanceDisplay } = useCoffeeDaoTokenBalance();

  const schema = useMemo(
    () =>
      yup.object({
        amount: Yup.amount({ min: 1, max: balanceDisplay ? parseFloat(balanceDisplay) : 999, allowZero: false }),
      }),
    [balanceDisplay]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditSpendAmountFormData>({
    schema,
    defaultValues: {
      amount: parseFloat(spendAmount).toString(),
    },
  });

  const onSubmit = (data: EditSpendAmountFormData): void => {
    onConfirm(data.amount);
  };

  return (
    <ModalCard align="left">
      <ModalCard.Heading translationKey="spend-token.edit.title" />
      <ModalCard.Message translationKey="spend-token.edit.description" />
      <AmountTextInputField
        name="amount"
        control={control}
        inputMode="decimal"
        errorMessage={errors.amount?.message}
        placeholder="$COFFEE"
      />
      <LocalizedText
        variant="secondary"
        category="body2"
        translationKey="spend-token.edit.balance-label"
        values={{ balance: balanceDisplay || '0' }}
      />
      <ModalCard.Action>
        <Button variant="primary" onClick={onCancel} className={gaClassName('spend-modal-edit-amount-cancel')}>
          <LocalizedText category="body1" weight="medium" translationKey="spend-token.edit.button-cancel" />
        </Button>
        <Button
          variant="secondary"
          onClick={handleSubmit(onSubmit)}
          className={gaClassName('spend-modal-edit-amount-confirm')}
        >
          <LocalizedText category="body1" weight="medium" translationKey="spend-token.edit.button-confirm" />
        </Button>
      </ModalCard.Action>
    </ModalCard>
  );
}
