import { formatUtils } from 'adam-frontend-shared';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import LocalizedText from 'components/LocalizedText';
import { CoffeeDaoLogo } from 'components/Logo';
import { Modal } from 'components/Modal';
import QrCode from 'components/QrCode';
import Text from 'components/Text';
import useUserName from 'hooks/useUserName';
import useWeb3 from 'hooks/useWeb3';
import ShopTag from './ShopTag';

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 24px;
  background: linear-gradient(180deg, #7b6d62 0%, #b69e8c 100%);
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Detail = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

interface Props {
  isOpen: boolean;
  onCloseModal?: () => void;
}

export default function ShopWalletModal({ isOpen, onCloseModal }: Props): JSX.Element {
  const { address } = useWeb3();
  const { username } = useUserName(address || '');

  const onClickCopyAddress = (): void => {
    if (address) {
      copy(address);
    }
  };

  return (
    <Modal isOpen={isOpen} size="small">
      <Content>
        <Header>
          <CoffeeDaoLogo variant="secondary" />
          <IconButton
            icon="close"
            iconColor="onPrimary"
            buttonColor="transparent"
            withoutBorder
            onClick={onCloseModal}
          />
        </Header>
        <Detail>
          <ShopTag />
          <Text category="body1" color="onPrimary">
            {username}
          </Text>
        </Detail>
        {address && (
          <>
            <QrCode data={address} width={280} height={280} variant="shop" />
            <Text category="h3" color="onPrimary">
              {formatUtils.toEllipsisAddress(address)}
            </Text>
            <Button onClick={onClickCopyAddress} variant="secondary">
              <LocalizedText category="body1" translationKey="wallet.detail.button-copy" />
            </Button>
          </>
        )}
      </Content>
    </Modal>
  );
}
