import { TransactionRecord } from '@adam-vault/adam-sdk-coffeedao';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IconButton from 'components/IconButton';
import LocalizedText from 'components/LocalizedText';
import { Modal } from 'components/Modal';
import useChainInfo from 'hooks/useChainInfo';
import { usePolygonToken } from 'hooks/useToken';
import useTransactionDisplay from 'hooks/useTransactionDisplay';
import useUserName from 'hooks/useUserName';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
`;

const CloseButton = styled(IconButton)`
  align-self: flex-end;
`;

const Title = styled(LocalizedText)`
  margin-bottom: 16px;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  padding: 24px 16px;
  border: 1px solid ${({ theme: { color } }) => color.border};
  border-radius: 16px;
`;

const Field = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const FieldLabel = styled(LocalizedText).attrs({ category: 'body1', color: 'caption' })`
  flex: 0 0 100px;
`;

const FieldValue = styled(LocalizedText).attrs({ category: 'body1', color: 'primary' })`
  flex: 1;
  text-align: right;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme: { color } }) => color.border};
  margin: 24px 0;
`;

const TransactionDetailLink = styled(LocalizedText)`
  text-align: center;
  margin-top: 24px;
  text-decoration: underline;
`;

interface Props {
  record: TransactionRecord;
  onCloseModal?: () => void;
}

export default function TransactionDetailModal({ record, onCloseModal }: Props): JSX.Element | null {
  const polygonToken = usePolygonToken();
  const getDisplay = useTransactionDisplay();
  const chainInfo = useChainInfo();

  const { titleText, dateTimeText, toDisplay, amountText, gasPrice, transactionHash } = useMemo(
    () => getDisplay(record),
    [getDisplay, record]
  );

  const { username: toUserName } = useUserName(toDisplay?.address);

  const scannerLink = chainInfo ? chainInfo.blockExplorerUrl : 'https://polygonscan.com';
  const transactionLink = `${scannerLink}/tx/${transactionHash}`;

  return (
    <Modal isOpen>
      <Container>
        <CloseButton
          icon="close"
          iconSize="24px"
          iconColor="primary"
          buttonSize="32px"
          buttonColor="transparent"
          onClick={onCloseModal}
          withoutBorder
        />
        <Title category="h3" color="primary" weight="medium" translationKey="transactions.detail.title" />
        <Content>
          <Field>
            <FieldLabel translationKey="transactions.detail.status-label" />
            <FieldValue translationKey="transactions.detail.success-label" />
          </Field>
          <Divider />
          <Field>
            <FieldLabel translationKey="transactions.detail.type-label" />
            <FieldValue>{titleText}</FieldValue>
          </Field>
          <Divider />
          <Field>
            <FieldLabel translationKey="transactions.detail.time-label" />
            <FieldValue>{dateTimeText}</FieldValue>
          </Field>
          <Divider />
          <Field>
            <FieldLabel translationKey="transactions.detail.to-label" />
            <FieldValue>
              {toDisplay.address} <br />
              {toUserName}
            </FieldValue>
          </Field>
          <Divider />
          <Field>
            <FieldLabel translationKey="transactions.detail.amount-label" />
            <FieldValue>
              {amountText} $COFFEE <br />
              {gasPrice && `Gas fee ${polygonToken.toDisplay(gasPrice, { maxDecimals: 6 })} MATIC`}
            </FieldValue>
          </Field>
          <Link to={transactionLink} target="_blank">
            <TransactionDetailLink category="body2" color="primary" translationKey="transactions.detail.button-view" />
          </Link>
        </Content>
      </Container>
    </Modal>
  );
}
