import { Adam } from '@adam-vault/adam-sdk';
import { Chain, NetworkUtils } from 'adam-frontend-shared';
import { atom } from 'jotai';
import { DEFAULT_PROVIDER, web3Atom } from './web3Atom';

export const adamAtom = atom<Adam>((get) => {
  const { signer, provider } = get(web3Atom);

  // TODO: add supported network in environment config
  const chainInfo = NetworkUtils.getChainInfo(process.env.REACT_APP_DEFAULT_NETWORK);
  if (!chainInfo) {
    // eslint-disable-next-line no-console
    throw new Error(`Does not support ${process.env.REACT_APP_DEFAULT_NETWORK} network!`);
  }
  const { network, envParam } = chainInfo;
  const sdkNetwork = Chain.ChainNetworkAdamSDKNetworkMapping[network];
  if (!sdkNetwork) {
    // eslint-disable-next-line no-console
    throw new Error(`SDK does not support ${sdkNetwork} network!`);
  }

  return new Adam(
    envParam.adamContractAddress,
    signer ?? provider ?? DEFAULT_PROVIDER,
    sdkNetwork,
    process.env.REACT_APP_ENV
  );
});
