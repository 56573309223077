import { useForm } from 'adam-frontend-shared';
import { useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import useSetNickname from 'hooks/useSetNickName';
import shopTheme from 'theme/shop';
import { gaClassName } from 'utils/gtmEventUtils';
import Button from './Button';
import FloatingLabelInputField from './form/FloatingLabelInputField';
import LoadingSpinner from './Loading';
import LocalizedText from './LocalizedText';
import { Modal, ModalHeader } from './Modal';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px 16px;
`;

const Form = styled.div`
  margin: 80px 0;
`;

const Footer = styled.div`
  margin-top: auto;
`;

interface Props {
  defaultNickname?: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

type FormData = {
  nickname: string;
};

export default function ChangeNicknameModal({ defaultNickname, isOpen, onRequestClose }: Props): JSX.Element {
  const { isLoading, setNickname } = useSetNickname(onRequestClose);

  const schema = yup.object({
    nickname: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ schema, defaultValues: { nickname: defaultNickname } });

  const onConfirm = useCallback(
    (data: FormData) => {
      setNickname(data.nickname);
    },
    [setNickname]
  );

  return (
    <ThemeProvider theme={shopTheme}>
      <Modal isOpen={isOpen}>
        <Container>
          <ModalHeader onClose={onRequestClose} />
          <Form>
            <FloatingLabelInputField
              name="nickname"
              control={control}
              errorMessage={errors.nickname?.message}
              label="edit-name.description"
              autoComplete="off"
            />
          </Form>
          <Footer>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={handleSubmit(onConfirm)}
              className={gaClassName('edit-name-modal-change-nickname')}
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <LocalizedText category="body1" weight="medium" translationKey="edit-name.button-confirm" />
              )}
            </Button>
          </Footer>
        </Container>
      </Modal>
    </ThemeProvider>
  );
}
