import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import IconCoffeeToken from 'assets/images/icon_coffee_token.svg';
import Icon from 'components/Icon';
import LocalizedText from 'components/LocalizedText';
import { SmallModal } from 'components/Modal';
import Text from 'components/Text';
import TransactionPreview from 'components/transaction/TransactionPreview';
import useChainInfo from 'hooks/useChainInfo';
import useTransactionDisplay from 'hooks/useTransactionDisplay';
import en from 'locales/en.json';
import { TransactionRecord } from 'types/TransactionRecord';

export type TranslationKey = keyof typeof en;

interface TransactionRecordModalProps {
  isOpenTransactionRecordModal: boolean;
  onTransactionRecordModalRequestClose: () => void;
  transactionRecord: TransactionRecord;
}

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 8px;
`;

const TitleText = styled(Text).attrs({ category: 'h3', weight: 'medium' })`
  width: 100%;
  letter-spacing: -0.02em;
`;

const TokenAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const TokenAmount = styled(Text).attrs({ category: 'h3', weight: 'medium' })`
  word-break: normal;
`;

const TransactionDateTimeRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const TransactionDateTime = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const ImgIconToken = styled.img.attrs({
  src: IconCoffeeToken,
})`
  width: 20px;
  height: 20px;
`;

const Divider = styled.div`
  margin: 16px 0px;
  background-color: ${({ theme: { color } }) => color.divider};
  height: 1px;
  width: 100%;
`;

const TransactionDetailLink = styled(Link)`
  display: flex;
  gap: 4px;
`;

const RemarkText = styled(LocalizedText).attrs({ category: 'body2' })`
  color: ${({ theme: { color } }) => color.remark};
`;

export function TransactionRecordModal(props: TransactionRecordModalProps): JSX.Element | null {
  const { isOpenTransactionRecordModal, onTransactionRecordModalRequestClose, transactionRecord } = props;

  const theme = useTheme();
  const chainInfo = useChainInfo();

  const getDisplay = useTransactionDisplay();
  const transactionDisplay = getDisplay(transactionRecord);

  if (!transactionDisplay) {
    return null;
  }

  const { fromDisplay, toDisplay, typeText, amountText, dateTimeText } = transactionDisplay;
  const scannerLink = chainInfo ? chainInfo.blockExplorerUrl : 'https://polygonscan.com';
  const transactionLink = `${scannerLink}/tx/${transactionRecord.transactionHash}`;

  return (
    <SmallModal
      align="left"
      isOpen={isOpenTransactionRecordModal}
      onRequestClose={onTransactionRecordModalRequestClose}
    >
      <TitleRow>
        <TitleText>{typeText}</TitleText>
        <TokenAmountWrapper>
          <ImgIconToken />
          <TokenAmount>{amountText}</TokenAmount>
        </TokenAmountWrapper>
      </TitleRow>
      <TransactionDateTimeRow>
        <Icon icon="time" size="16px" color={theme.color.primary} />
        <TransactionDateTime>{dateTimeText}</TransactionDateTime>
      </TransactionDateTimeRow>
      <Divider />
      <TransactionPreview
        fromName={fromDisplay.name}
        fromAddress={fromDisplay.address}
        toName={toDisplay.name}
        toAddress={toDisplay.address}
      />
      <TransactionDetailLink to={transactionLink} target="_blank">
        <RemarkText translationKey="transactions.detail.button-view" />
        <Icon icon="link" size="16px" color="remark" />
      </TransactionDetailLink>
    </SmallModal>
  );
}
