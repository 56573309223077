import { StylelessInput as Input, StylelessInputProps as InputProps } from 'adam-frontend-shared';
import styled from 'styled-components';
import ErrorMessage, { Props as ErrorMessageProps } from 'components/form/ErrorMessage';
import LocalizedText from 'components/LocalizedText';
import { TranslationKey } from 'constants/languages';

export interface Props extends InputProps, ErrorMessageProps {
  label?: TranslationKey;
  placeholder?: TranslationKey;
}

interface StyledInputProps {
  hasError?: boolean;
}

const Container = styled.div<StyledInputProps>`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px;
  border-bottom: ${({ theme: { color }, hasError }) => `1px solid ${hasError ? color.error : color.border}`};
  position: relative;
`;

const Label = styled(LocalizedText)`
  position: absolute;
  bottom: 72px;
  left: 16px;
  color: ${({ theme: { color } }) => color.textInputPlaceHolder};
  transform-origin: top left;
  transition: all 0.2s ease-in-out;
  text-align: left;
  z-index: -1;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  position: absolute;
  bottom: -32px;
  right: 0;
`;

const StyledInput = styled(Input)<StyledInputProps>`
  width: 100%;
  height: 48px;

  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: ${({ theme: { color } }) => color.primary};
  border: none;

  &:placeholder-shown:not(:focus) + ${Label} {
    bottom: 24px;
    transform: scale(1.5);
  }
`;

export default function FloatingLabelInputField(props: Props): JSX.Element {
  const { className, errorMessage, label, placeholder, ...inputProps } = props;
  return (
    <Container className={className} hasError={!!errorMessage}>
      <StyledInput placeholder="" {...inputProps} />
      <Label category="body1" translationKey={label || placeholder} />
      {!!errorMessage && <StyledErrorMessage errorMessage={errorMessage} />}
    </Container>
  );
}
