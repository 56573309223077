import { useState, useCallback } from 'react';
import { CustomError } from 'constants/error/CustomError';
import { toast } from 'utils/toastUtils';
import useCoffeeDao from './useCoffeeDao';
import useCoffeeDaoClient from './useCoffeeDaoClient';
import useSelfInfo from './useSelfInfo';

interface Return {
  setNickname: (nickname: string) => Promise<void>;
  isLoading: boolean;
}

export default function useSetNickname(continueCallback: () => void): Return {
  const [isLoading, setIsLoading] = useState(false);
  const coffeeDao = useCoffeeDao();
  const coffeeDaoClient = useCoffeeDaoClient();
  const { setSelfInfo } = useSelfInfo();

  const setNickname = useCallback(
    async (nickname: string) => {
      if (!coffeeDao) {
        return;
      }
      try {
        setIsLoading(true);
        await coffeeDaoClient?.request(coffeeDao.updateMyself(nickname));
        setSelfInfo((info) => (info ? { ...info, name: nickname } : info));
        continueCallback();
      } catch (error) {
        toast(CustomError.GENERIC_ERROR.message);
      } finally {
        setIsLoading(false);
      }
    },
    [coffeeDao, coffeeDaoClient, continueCallback, setSelfInfo]
  );

  return { setNickname, isLoading };
}
